.faq-c{
    margin: 20px 0;
    padding: 12px;
}

.faq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
}

.faq h2{
    font-size: 22px;
    margin: 12px 0;

}

.faq p{
    font-size: 18px;
    margin: 12px 0;
    line-height: 1.3em;
}

.faq div{
    padding: 8px;
    -webkit-box-shadow: 3px 3px 0px 0px #0e0d27;
    -moz-box-shadow: 3px 3px 0px 0px #0e0d27;
    box-shadow: 3px 3px 0px 0px #0e0d27;    border-radius: 12px;
}

@media screen and (max-width:550px){
    .faq-c{
        padding: 4px;
    }

    .faq h2{
        font-size: 18px;
    }

    .faq p{
        font-size: 15px;
    }
}