.footer-c{
    margin-top: 140px;
    background-color:#0e0d27 ;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    padding: 0px 20px 0px 20px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    font-family: 'Roboto',sans-serif;

}

.footer{
    width: 100%;
    margin-top: 20px;
    padding: 18px;
    color: white;

    text-align: center;
    border-bottom: none;
}

.form-c form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    border: 2px solid #010045;
    padding: 12px;
    font-size: 17px;
    text-align: start;
    color:#010045;
    position: relative;
    background-color: white;
}

.form-c form::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top:10px;
    right: -12px;
    border: 2px solid white;
    border-radius: 10px;
    z-index: -1;
}


.form-c form label{
    width: 100%;
   
}

.form-c form input{
    margin: 20px 0px;
    padding: 12px;
    width: 100%;
    height: 35%;
    font-size: 17px;
    letter-spacing: 1px;
    border: 2px solid #010045;
    border-radius: 7px;
}

textarea{
    margin: 20px 0px;
    padding: 12px;
    width: 100%;
    height: 35%;
    font-size: 17px;
    letter-spacing: 0.5px;
    border: 2px solid #010045;
    border-radius: 7px;
}


.footer-about{
    text-align: center;
    font-size: 20px;
    color: white;

}

.footer-about h3{
    font-size: 40px;
    margin: 10px 0px;
}

.footer-about p{
    margin: 10px 0px;
    font-size: 18px;
    text-align: justify;
    line-height: 1.3em;
    color:rgb(189, 222, 255);
}


.footer-r{
    color: white;

    display: flex;
    width: 60%;
    flex-wrap: wrap;
    margin: 2% auto;
    justify-content: center;
    gap:20px;
    text-align: center;
}





.footer-r h3{
    font-size: 22px;
    color: white;

    margin: 10px 0px;
}

.footer-r p{
    font-size: 18px;
    margin: 4px 0px;

}

.footer-socials{
    display: flex;
    width: 300px;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
    gap:12px;
}

.footer-socials img{
    width: 100%;
    height: auto;
}

.paypal{
    margin: 2% 0;
    color: white;

}

.paypal img{
    width: 100%;
    height: auto;
}

.copyright{
    width: 100%;
    color: white;

    text-align: center;
    margin: 10px 0px;
}

@media screen and (max-width: 920px) {
    .footer-c .footer-about h3{
        font-size: 25px;}
        
        .footer-about p{
          font-size: 15px;}
        
        
        .footer-c .footer-r{
          display: flex;
          width: 100% !important; 
          margin: 0;
        }
        
        .footer-c .footer-r p{
          font-size: 15px;
        
        }
        
        .paypal h2{
        font-size: 16px;
        margin: 6px 0;
        }
        
}

@media screen and (max-width: 500px){
    body .footer .footer-r{
      display: flex ;
      flex-direction: column;
      gap:12px;
      text-align: center;
      font-size: 15px !important;
    }
  
  
    .calculator-c .calculator .tier div p{
      font-size: 12px;
    }
  
     .footer-c  .footer-socials {
      width: 200px ;
    }
  
    .contact .navbar .main-ul{
      gap:12px !important;
      display: none;
    }
  
  }


  @media screen and (max-width:450px){
    .footer-c .footer-about .footer-socials img{
        width: 60%;
        height: auto;
      }
  }