.head-video-c{
    display: flex;
    width: 100%;
    height: 100vh;
}

video{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -10;
    min-width: 100%;
    min-height: 100%;
}

.bg-vid::after{
    position: absolute;
    top:0;
    content: '';
    left: 0;
    z-index: -1;
    background-color: #0e0d27;
    width: 100%;
    opacity:0.7;
    height: 100%;
}

.head-main{
    margin: 4% 0;
    display: flex;
    text-align: center;
    padding: 20px;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    gap:24px;
}

.head-text h1{
    font-size: 50px;
    margin: 7px 0;
}

.head-text p{
    font-size: 20px;
    margin: 12px 0;
    padding: 0 12px;
}

.h-stats{
    display: flex;
    flex-direction: row;
    gap:5px;
    position: relative;
    padding: 0 6px;
    border-radius: 12px;
}
.h-stats::after{
    position: absolute;
    content: '';
    border-radius: 12px;

    top:0;
    left: 0;
    width: 100%;
    height:100%;
    z-index: -1;
    background-color: #08072c;
    opacity: 0.5;
}

.h-stats div{
    padding: 20px;
}

.h-stats-2{
    width: 60%;
    text-align: center;
    margin: 20px auto;
  font-size: 17px;
    padding: 0px 12px;
    color: #dcdbff;
}

.h-stats-2 div:nth-child(even){
    border-bottom: 1px solid white;
    padding: 30px 0;
}

.developT{
  font-family: 'Bebas Neue', cursive; 
   letter-spacing: 0.2em;
  font-size: 24px !important;
  color: #dbdbf7;
}

.head-btn-connect{
  position: relative;
padding: 12px 18px;
border-radius: 7px;
font-size: 18px;
margin: 20px 0;
border: 2px solid white;
color: #0e0d27;
z-index: 5;
background-color: transparent;
}

.head-btn-connect::after{
  position: absolute;
  content: '';
  z-index: 1;
  bottom:0%;
  border-radius: 7px;

  left:0%;
  width: 0%;
  height:0%;
  z-index: -2;
  background-color: #1b195d;
  color: white;
  border-radius: 7px;
  transition: all 0.24s ease-in-out;

}

.head-btn-connect:hover::after{
  width: 100%;
  height: 100%;
  transition: all 0.24s ease-in-out;
}


/* OUR SERVICES */

.service-container{
    margin: 140px 20px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap:20px;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

}

.service-c-text{
    position: relative;
  z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding: 12px;
    width: 90%;
    margin: 0 auto;
    border-radius: 12px;
    background-image: url(../img/service.webp);
    background-color: #0e0d27; /* Used if the image is unavailable */
    height: 260px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.service-c-text::after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    z-index: -1;
    background-color: #0e0d27;
    opacity: 0.65;
}




.service-c-text h1{
    color:#ffffff;
    padding: 12px;
    border-radius: 12px;
    /* background-color: white; */
    font-size: 56px;
}
.service-c-text p{
    font-size: 18px;
    color:#ffffff;
    padding: 16px 10px;
}

.our-services{
    width: 100%;
    display: flex;
gap: 24px;
flex-wrap: wrap;
justify-content: center;

}

.sm{
    width: 280px;
    height: 280px;
    font-size: 14px;
    padding: 18px;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    -webkit-box-shadow: 4px 4px 0px 0px white;
    -moz-box-shadow: 4px 4px 0px 0px white;
    box-shadow: 4px 4px 0px 0px white;
    border-radius: 16px;
}

.sm p{
  font-size: 17px;
}

.sm h2{
    color:#b7b8e7;
    font-size: 24px;
    z-index: 5;
    padding: 6px;
}

.sm::after{
  position: absolute;
  content: '';
  bottom:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e0d27;
  opacity: 0.8;
  border-radius: 12px;
  z-index: -1;
}


.sm img{
  position: absolute;
  top:10%;left:10%;
  width: 70%;
  height: auto;
  z-index: -2;
  opacity: 0.4;
  height: auto;
}



.service-head-text{
    font-size: 17px;
    margin-bottom: 20px;
    text-align: center !important;
}


.service-head-text h1{
    font-size: 70px;
    font-size: bolder;
}

.service-head-text p{
    font-size: 18px;
}



.testimonials{
    margin: 40px 20px;
    color:white;
}

.reviews{
    display: grid;
    grid-template-columns: 50%;
    align-items: center;
    justify-content: center;
    gap:5%;
}

.review-head-text{
    text-align: center;
    margin: 40px 0;
    font-size: 15px;

    color:#ffffff;
}

.review-head-text h1{
    font-size: 50px;
    text-align: center;
}

.review-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}

.review{
    border-radius: 12px;
    width: 100%;
    height: 240px;
    position: relative;
    background-color: #0e0d27;
    color:#ffffff;
    border:4px solid rgb(60, 118, 205);

    font-size: 17px;
    padding: 17px;
}

.review h3{
font-size: 24px;
}

.review p{
    color:rgb(189, 222, 255); 
    margin: 8px 0;
    }
    

.review-slider button{
    margin: 20px;
    background-color: #0e0d27;
    border: none;
}

.review-slider button img{
    width: 10%;
    height: auto;
    background-color: #0e0d27;
}



.customer{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;

    gap:20px;
    margin: 4% 0;
}

.customer img{
    width: 60px;
    height: 60px;
}

.customer div{
    color: white;
    padding-bottom: 5px;
    border-bottom: 2px solid rgb(60, 118, 205);
}

.customer p{
  border: none;
  padding: 0;
}


/* MEDIA */

@media only screen and (max-width: 1200) {
   .head-sec {
margin: 100%;    }
  }

  @media screen and (max-width: 1200px) {



 
 
 .footer-about .footer-socials {
  width: 260px;
}

.footer-c .copyright{
  font-size: 16px;
  color: white;
  margin: 50px 0;
}

}

@media screen and (max-width:1080px){
  .h-stats-2{
    display: none;
  }

  .head-text p{
    font-size: 20px;
  }

  .head-text h1{
    font-size: 40px;
  }
  
  .h-stats{
    font-size: 14px;
  }
}



@media screen and (max-width: 920px) {


  body .navtop{
    display: none;
  }

  body .navbar{
padding: 3% 0;
border: none;
display: flex;
  }




  body .navbar ul li{
    font-size: 15px !important;

  }


 
.service-c .service-head-text h1{
  font-size: 40px;
  font-size: bolder;
  margin-bottom: 4%;
}
.service-c .service-head-text p{
  font-size: 17px;
  font-size: bolder;
  margin-bottom: 3%;
}


/* REVIEW */
.review-c .review-head-text h1
{
  font-size: 30px;
}
.reviews{
  display: flex;
  flex-direction: column;
}


/* FOOTER */


.footer-c .footer-about h3{
font-size: 25px;}

.footer-about p{
  font-size: 15px;}


.footer-c .footer-r{
  display: flex;
  width: 100% !important; 
  margin: 0;
}

.footer-c .footer-r p{
  font-size: 15px;

}

.paypal h2{
font-size: 16px;
margin: 6px 0;
}


  .App .testimonials{
    margin: 60px 0;
  }

  .testimonials .service-head-text h1{
    font-size:50px;
  }


  .testimonials .reviews{
    display: block;
    width: 80%;
    margin: 0 auto;
  }

  .review-slider{
    margin: 70px 0;
  }

  .reviews .review-head-text {
    margin: 0px 0;
    
    color: white;
  }
  .reviews .review-head-text h1{
    font-size: 32px;
  }

  .App .footer-c{
    margin-top:100px;
  }

  .service-container{
    margin: 80px 0;
  }

  .footer-about .footer-socials img {
    width: 80%;
    height: auto;
}
}

@media screen and (max-width: 580px){


  .customer div h2{
    font-size: 24px;
    margin: 5px 0;
  }

  .customer div p{
    font-size:17px;
  }

  .head-main .head-text p{
    font-size: 19px;
  }

  .head-main .head-text h1{
    font-size:36px;
  }

  .service-container .service-c-text {
    height: 180px;
  }


  .service-container .service-c-text h1{
    font-size: 40px;
  }

  .service-container .service-c-text p{
    font-size: 18px;
    padding: 0;
  }

  .service-c-text::after {
    height: 100%;
  }

  .navbar .logo img {
    width: 60px;
    height: 30px;
    margin-left: 12px;
}

}


@media screen and (max-width: 500px){
  body .footer .footer-r{
    display: flex ;
    flex-direction: column;
    gap:12px;
    text-align: center;
    font-size: 15px !important;
  }


     .footer-c  .footer-socials {
    width: 200px ;
  }

  .contact .navbar .main-ul{
    gap:12px !important;
    display: none;
  }

}

@media screen and (max-width:750px){
  .head-video-c{
    display: flex;
    width: 100%;
    height: fit-content;
  }
}


@media screen and (max-width:450px){

  .head-main{
    padding: 0;
  }

.head-main .head-text h1{
    font-size: 27px;
  }

  .head-main  .h-stats{
    padding: 0;
  }

  .head-main  .h-stats div{
    padding:10px;
  }



  .head-main .head-text p{
    font-size: 16px;

  }


  .head-main  .h-stats div h2{
    font-size: 16px;

  }

  .head-main  .h-stats div p{
    font-size: 14px;

  }


  .service-container{
margin: 50px 0;
  }

  .service-container .service-c-text h1 {
    font-size: 30px;
}
.service-container .service-c-text p {
  font-size: 16px;
  padding: 0;
}


.sm{
  height: 220px;
}

.sm h2{
  font-size: 24px;
}

.sm p{
  font-size: 15px;
}

.footer-c .footer-about .footer-socials img{
  width: 60%;
  height: auto;
}

.navbar .logo img{
  width: 50px;
  height: 25px;
}




.testimonials  .service-head-text h1{
  font-size: 30px;
}

.testimonials  .service-head-text p{
  font-size: 15px;
}

.form-c form input{
  font-size: 15px !important;
}

.review-slider .review-head-text p{
  font-size: 15px !important;
}

.review-slider .review-head-text h1{
  font-size: 22px !important;
}

.review .customer div h2{
  font-size: 20px;
}

.review .customer div p{
  font-size:15px;
}

.review p{
  font-size: 16px;
}

.customer span{
  display: none !important;
}

}



@media screen and (max-width:320px){
  .head-main  .h-stats{
    display: none !important;

  }

  .sm {
    width: 220px;
    height: 220px;
    padding: 4px;
}

.sm h2{
  font-size: 20px;
}

.sm p{
  font-size: 14px;
  line-height: 1.3em;
  margin: 5px 0;
}

.review p {
  font-size: 13px;
}

.customer{
  display: inline;
}


}

