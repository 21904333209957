
.contact{
    position: relative;
    font-family: 'Roboto',sans-serif;

}
.contact-text{
    position: relative;
    background-color:#0e0d27;
    color: white;
    text-align: center;
    padding: 5%;
    width: 80%;

    margin:  20px auto;
    border-radius: 12px;
    background-image: url(../img/contact.webp);
    background-color: #0e0d27;
    height: 200px;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contact-text::after{
    position: absolute;
    content: '';
    bottom:0;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: #0e0d27;
    z-index: -1;
    opacity: 0.6;
}

.contact-text h1{
 font-size: 40px;
 color: #ffffff;
}

.contact-text p{
    font-size: 17px;
    margin: 12px 0;
    line-height: 1.3em;
}

.contact-form{
    display: grid;
    grid-template-columns: 60% auto;
    width: 90%;
    margin: 50px auto;
    border: 2px solid #ffffff;
    border-radius: 12px;
}

.contact-form-c{
    border-radius: 12px;

}

.contact-form{
    border-radius: 12px;

}

.cform-c{
    background-color: #0e0d27;
    border-radius: 12px;

}
.cform-c form{
    padding: 5%;
background-color: white;
    display: flex;
    color:#0e0d27;

    flex-direction: column;
    justify-content: center;
    border:  2px solid #0e0d27;
    border-top-left-radius:12px;
    border-bottom-left-radius:12px;
    
}

.cform-c form input{
    border: 2px solid #0e0d27;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    color:#0e0d27;
    font-size: 17px;
    margin: 12px 0;
}

.contact-form img{
    width: 10%;
    height: auto;
}

.cform-text{
    background-color: #0e0d27;
    color: white;
    padding: 3%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

}

.c-about li{
margin: 12px 0px;
}

.c-socials{
    display: flex;
    width: 40%;
    margin: 12px 0;
    justify-content: center;
    align-items: center;
}

.c-btn{
    background-color: white;
    box-shadow: 8px 8px 0px 0px #0e0d27;
    border-radius: 12px;
    font-size: 18px;
    width: 40%;
    margin: 16px auto;
    padding: 12px 10%;
    color:#0e0d27;
    border: 2px solid #0e0d27;

}

.c-socials img{
    width: 50%;
    height: auto;
}


@media screen and (max-width:800px){
    .contact .contact-text h1{
        font-size: 30px;
    }
    .contact .contact-text p{
        font-size:16px;
    }

    .contact-text{
        height: 150px;
        padding: 8px;
    }

    .contact-text::after{
        height: 100%;
    }

    .cform-text h1{
        font-size: 24px;
        margin: 10px 0;
    }

}

@media screen and (max-width:500px){
    .contact-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    .cform-c form{
        border-radius: 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

    }
}

@media screen and (max-width:350px){

    .contact-text{
        height: 120px;
        padding: 4px;
    }
    .contact .contact-text h1 {
        font-size: 24px;
    }

    .contact .contact-text p {
        font-size: 13px;
    }
}