.au-c{
    width: 90%;
    margin: 4% auto;
}

.au-text{
    margin: 40px 0;
    font-size: 18px;
    text-align: center;
}

.au-text span{
    color: aqua;
    font-size: 60px;
}

.au-text p{
    margin: 12px;
    line-height: 1.4em;
}

.au-stats-c{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 80px 0;
    justify-content: center;
}

.au-stats-c img{
    width: 60%;
    border-radius: 12px;
    height: auto;
}

.au-stats-l{
    position: absolute;
    top: 20px;
    left: 10%;
    width: 280px;
    color:white;
    font-size: 20px;
    gap:20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.au-stats-l div{
    width: 120px;
    height: 120px;
    position: relative;
    z-index: 1;
    padding: 12px;
    border-radius: 6px;
}

.au-stats-l div::after{
    position: absolute;
    content: '';
    top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #0e0d27;
opacity: 0.8;
z-index: -3;
border-radius: 6px;


}


.our-story{
    padding: 24px;
    color: #0e0d27;
    font-size: 18px;
    margin: 120px 0;
    background-color: white;
    line-height: 1.3em;
}

.our-story h2{
    font-size:40px;
    margin: 14px 0;
}

.ethos-c{
    margin: 120px 20px;
    text-align: center;
}

.ethos-c h2{
    font-size: 40px;
}

.ethos{
display: flex;
flex-wrap: wrap;
gap:20px;
margin: 20px 10px;
justify-content: center;
align-items: center;
}

.ethos div{
    padding: 16px;
    font-size: 18px;
    -webkit-box-shadow: 3px 3px 0px 0px white;
    -moz-box-shadow: 3px 3px 0px 0px white;
    box-shadow: 3px 3px 0px 0px white;
    border-radius: 12px;
}


@media screen and (max-width:550px){
    .au-text h1{
        font-size: 30px;
    }

    .au-text span{
        font-size: 36px;
    }

    .au-text p{
        font-size: 18px;
    }

    .au-stats-l div{
        font-size: 17px;
        height: fit-content;

    }

    .our-story{
        font-size: 16px;
    }

    .our-story h2 {
        font-size: 30px;
        margin: 14px 0;
    }


    .ethos-c{
        margin: 60px 0;
    }

    .ethos div {
        padding: 16px;
        font-size: 17px;
        box-shadow: 3px 3px 0px 0px white;
        border-radius: 12px;
    }
}


@media screen and (max-width:350px){
    .au-stats-l {
        top: 0;
        left: 0;
        display: none;
    }

    .au-stats-c{
        margin: 20px 0;
    }

    .au-stats-c img {
        width:80%;
        border-radius: 12px;
        opacity: 0.9;
        border: 2px solid white;
        height: auto;
    }

    .au-text p {
        margin: 2px;
        margin-top: 12px;
        line-height: 1.4em;
    }

    .ethos div {
        padding: 12px;
        font-size: 15px;
        box-shadow: 3px 3px 0px 0px white;
        border-radius: 12px;
    }
}