.service-m-c{
    /* position: relative; */
    width: 100%;
    height:80vh;
    z-index: 2;
}

.service-m-c::after{
    position: absolute;
    content: '';
    top:0;
    left: 0;
    background-color: #0e0d27;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: -1;
}

.s-bg-img{
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -2;
}

.s-bg-img img{
    width: 100%;
    height: auto;
}



.service-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    padding: 40px;
    text-align: center;
}

.service-head h1{
    font-size: 60px;
    margin: 10px 0;
}

.service-head p{
    font-size: 19px;
    margin: 10px 0;
}

.pservices-c{
    position: relative;
    z-index: 2;
    width: 90%;
    margin: 10px auto;
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    color:#0e0d27;
}


.pservices{
    margin: 12px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap:20px;
}

.pservice{
    position: relative;
    padding: 12px;
    width: 320px;
    -webkit-box-shadow: 3px 3px 3px 0px rgba(14,13,39,1);
-moz-box-shadow: 3px 3px 3px 0px rgba(14,13,39,1);
box-shadow: 3px 3px 3px 0px rgba(14,13,39,1);
border-radius: 12px;

}

.pservice h2{
    margin: 10px 0;
}

.pservice img{
    position: absolute;
    top:-10px;
    left:-10px;
    z-index: -1;
    opacity: 0.6;
    width: 20%;
    height: auto;
}

@media screen and (max-width:600px){

    .service-m-c {
        /* position: relative; */
        width: 100%;
        height: fit-content;
        z-index: 2;
    }

     .service-head h1 {
        font-size: 40px;
        margin: 10px 0;
    }

    .service-head p{
        font-size: 17px;
        margin: 10px 0;
    }

    .pservice h2 {
        font-size: 20px;
        margin: 10px 0;
    }

    .pservice p{
        font-size: 17px;
        margin: 10px 0;
    }

    .s-bg-img img {
        width: 100%;
        height: auto;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;

    }

    .service-head{
        padding: 12px;
    }
}

@media screen and (max-width:400px){
    .service-head h1 {
        font-size: 30px;
        margin: 10px 0;
    }

    .service-head p{
        font-size: 15px;
        margin: 10px 0;
    }

    .pservice h2 {
        font-size: 19px;
        margin: 10px 0;
    }

    .pservice p{
        font-size: 15px;
        margin: 10px 0;
    }
}