*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: white;
}

img{
  width: 100%;
  height: 100%;
}

body{
  background-color: #0e0d27;
  font-family: 'Roboto', sans-serif;
  color: white;
}

.logo img{ 
  width: 30%;
  height: auto;
}

ul li{
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #0e0d27;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
}

video{
  width: 100%;
  border-radius: 14px;
}

input{
  outline: none;
  border: none;
}

