.newsletter-m{
    font-family: Roboto,sans-serif;
    width: 50%;
    padding: 24px;
    border-radius: 8px;
    border: 2px solid #bfcaef;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap:24px;
    margin: 120px auto;
    -webkit-box-shadow: 3px 3px 1px 0px #bfcaef;
-moz-box-shadow: 3px 3px 1px 0px #bfcaef;
box-shadow: 3px 3px 1px 0px #bfcaef;
}

.newsletter-m form{
    display: flex;
    flex-direction: column;
    gap:12px;
    width: 100%;
    justify-content: center;
}

.newsletter-m form input{
    padding: 8px;
    background-color: transparent;
    color: #bfcaef;
    background-color: #0e0d27;
    font-size: 17px;
    width: 100%;
    margin: 12px 0;
    border-bottom: 2px solid #bfcaef;
}

.newsletter-m form button{
    cursor: pointer;
    width: 120px;
    position: relative;
    margin: 0 auto;
    background-color: #0e0d27;
    color: white;
    border-radius: 8px;
    border: 2px solid white;
    padding: 8px 24px;
    font-size: 17px;
    z-index: 2;
}


.newsletter-m form button::after{
    position: absolute;
    content: '';
    top:0;
    z-index: -1;
    border-radius: 8px;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #1b195d;
    transition: all 0.24s ease-in-out;
}

.newsletter-m form button:hover::after{

    width: 100%;
    transition: all 0.24s ease-in-out;
}

