.navbar{
    display: grid;
    z-index: 20;
    width:90%;
    margin: 0 auto;
    /* color:white; */

    margin: 0 auto;
    grid-template-columns: 120px auto;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    transition: all 0.3s;
    border-bottom: 1px solid rgb(208, 208, 208);
    font-family: 'Roboto',sans-serif;

}

.contact-nav-btn{
    border: 2px solid rgb(255, 255, 255);
    padding: 10px 18px;
    border-radius: 10px;
    font-size: 15px;
    background-color: transparent;
    transition: all 0.2s ease-in-out;

}

.contact-nav-btn:hover{
    background-color: #39369e;
    transition: all 0.2s ease-in-out;
}


.sticky {
    position: fixed !important;
    top: 0;
    width: 100%;
    background-color: white;
  }

  .sticky ul li{
    color: #010045 !important;

  }

  .sticky button
 { 
    background-color: #39369e;
    }


  .sticky button:hover{
    background-color: #416ed6 !important;

  }

  .sticky ul li a{
    color: #010045 !important;

  }

  .martop{
    margin-top: 120px !important;
  }

.navtop{
    border-top:none;
    color:white;
    display: grid;
    grid-template-columns: 15% 15%;
    align-items: center;
    justify-content: end;
    padding: 7px;
    gap: 2%;
    font-size: 13px;
}

.logo img{
    width: 80px;
    height: 40px;
}

.navtop div{
    display: flex;
    align-items: center;
    gap:3%;

}

.navtop img{
    width: 10%;
}



.navbar ul{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color:#ffffff;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
    gap:40px;
}

.navbar ul li{
    cursor: pointer;
    position: relative;
    color:white;
    font-weight: 500;

}


.navbar ul li::before{
    position: absolute;
    content: '';
    width: 0%;
    height: 2px;
    bottom: -2px;
    left:0;
    background-color: #39369e;
    transition: all 0.3s ease-in-out;
}

.navbar ul li:hover::before{
width: 100%;
transition: all 0.3s ease-in-out;
}

.form-btn{
    background-color: white;
    border-radius: 12px;
    border:2px solid #010045;
    box-shadow: 8px 8px 0px 0px #010045;
    transition: all 0.13s ease-in;
    font-size: 18px;
    padding: 14px 20%;
    color:#0e0d27;
    margin: 12px 0;
    }
    
    .form-btn:hover{
        box-shadow: 6px 6px 0px 0px #010045;
    transition: all 0.13s ease-in;
    }
    

    .ham-c{
        position: relative;
    }

.ham-ul{
    position: absolute;
    z-index: 200;
  background-color: white;
    display: grid !important;
    display: none;  
    grid-template-columns: 1fr 1fr 1fr;
    color:#010045 !important;
    padding: 15px;
    right: 0;
    border: 2px solid #010045;
    border-radius: 12px;
    width: 300px;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap:12px !important;
    font-size: 16px ;
}

.ham-ul li a
{
    font-size: 14px;
    color: #0e0d27 !important;
}

.ham-ul li{
position: relative;
}





.hide{
    display: none !important;
    transition: all 0.2s ease-in-out;

}

.ham-img{
    width: 8%;
    height: auto;
    margin-left: auto;
    margin-right: 10px;
}


@media screen and (max-width:400px){
    .ham-ul { 
     
        grid-template-columns: 1fr 1fr;
        width: 200px;
        gap: 12px !important;
        font-size: 13px;
    }
}