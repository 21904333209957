.achivements{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap:20px;
    background-color: white;
    padding: 3%;
    margin: 20px 0;
}

.achivements img{
    width: 20%;
    height: auto;
}

.stats{
    width: 240px;
    position: relative;
    padding: 12px;
    color:white;
    background-color: #0e0d27;
border-radius: 12px;
text-align: center;
}


.stats h2{
    font-size: 32px;
    margin: 10px 0px;
}

.stats p{
    font-size: 18px;
    margin: 10px 0px;
    color:#bfbeda;}


    @media screen and (max-width:850px) {
        .stats p{
            font-size: 17px;
        }

        .stats h2{
            font-size: 26px;
            margin: 10px 0px;
        }
    }

    @media screen and (max-width:450px){
        .stats p{
            font-size: 15px;
            text-align: center;
        }

        .stats h2{
            font-size: 24px;
            margin: 5px 0px;
            text-align: center;

        }

        .stats img{
            width: 15%;
            margin-left: 40%;
        }
    }