.hww-m{
    display: flex;
    flex-direction: column;
    gap:12px;
    padding: 16px;
}

.hwws{
    -webkit-box-shadow: 3px 3px 1px 0px rgba(14,13,39,1);
    -moz-box-shadow: 3px 3px 1px 0px rgba(14,13,39,1);
    box-shadow: 3px 3px 1px 0px rgba(14,13,39,1);
    border-radius: 12px;
    padding: 20px;
}
.hwws p{
    margin: 12px 0;
    line-height: 1.3em;
}

@media screen and (max-width:420px){

    .hww-m{
        padding: 5px;
    }

.hwws{
    padding: 8px;
}

.hwws h2{
font-size: 19px;
}
.hwws p{
    font-size: 15px;

}
}