
.blog-main{
    background-color: #0e0d27;
    font-family: 'Roboto',sans-serif;
}

.blog-heading{
 
    margin: 40px;
    width: fit-content;
    border-bottom: 1px solid rgb(136, 136, 136);
}

.blog-heading h1{
    color: white;
    font-size: 80px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    text-shadow: 
                          5px 5px white,
                          20px 20px transparent;
}

.blog-m-c{
    margin: 60px 20px;
}


.blog-m{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap:30px;
}



.blog-highlight h2{
    font-size: 25px;
    color: #ffffff;
    margin: 12px 0px;
}

.blog-highlight p{
    font-size: 17px;
    color: rgb(189, 222, 255);
}


.blog-highlight img{
width: 100%;
border-radius: 12px;

height: auto;
}

.m-blogs{
    display: flex;
    flex-direction: column;
  
    gap:40px;
    width: 55%;
    color: white;
}


.m-blogs h2{
    font-size: 25px;
    margin: 10px 0;
}

.m-blogs p{
    font-size: 17px;
    color: rgb(189, 222, 255);
}

.b-date
{
    margin-top: 8px;
    font-size: 14px !important;
    color: rgb(140, 111, 111) !important;
}

.t-blog{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:12px;
}

.t-blog img{
    width: 25%;
    height: auto;
    border-radius: 12px;

}

.t-blog h2{
   color: white;
}


/* MORE BLOGS */

.more-b-c{
    padding: 60px 20px;
    background-color: white;
}

.more-b-heading{
    margin: 12px 0;
    color: #0e0d27;
}

.more-b-heading h2{
    font-weight: 700;
    margin: 12px 0;
    color: #0e0d27;
    font-size: 30px;
}

.more-b{
    margin: 40px 0;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    gap:20px;
}

.more-blog{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:8px;
    height: 420px;
    color: #0e0d27;
    font-size: 17px;
    cursor: pointer;
    background-color: white;
    border: 2px solid #010045;
    box-shadow: 8px 8px 0px 0px #010045;
    padding: 12px;
    border-radius: 12px;
}

.more-blog h2{
    margin: 8px 0;
}

.more-blog img{
    width: 90%;
    height: auto;
    border-radius: 12px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.slider-btn{
    width: 10%;
    margin: 10px auto;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.slider-btn img{
    width: 40%;
    cursor: pointer;
    height: auto;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


@media screen and (max-width:1080px){

    .blog-m{
        flex-direction: column;
        gap:40px;
    }

    .blog-highlight{
        padding: 12px;
        border-bottom: 1px solid grey;
    }

    .blog-highlight h2{
        font-size: 30px;
    }
}

@media screen and (max-width:850px){
    .more-b-c .more-b{
        display: flex;
        flex-direction: column;
        gap:20px;
    }

    .more-blog{
        flex-direction: row;
        align-items: center;
    }

    .more-blog img{
        width: 30%;
        height: auto;
    }

    .slider-btn img{
        width: 80%;
        height: auto;
    }
}


@media screen and (max-width:600px){
   .blog-m-c .blog-m .blog-highlight h2{
        font-size: 20px;
    }

    .blog-m-c .blog-m .blog-highlight p{
        font-size: 16px;
    }

    .t-blog div h2{
font-size: 19px;
    }

    .t-blog div p{
font-size: 15px;
    }

    .b-date{
        font-size: 12px !important;
    }


    .more-b-c .more-b-heading h2{
        font-size: 20px;
        
    }

    .more-blog div h2{
        font-size: 19px;
            }
        
            .more-blog div p{
        font-size: 15px;
            }
        
            .slider-btn img{
                width: 90%;
                height: auto;
            }
}