.blogabout-main{
    background-color: #0e0d27;
    font-family: 'Roboto',sans-serif;
    position: relative;
}

.share-to-c{
    position: absolute;
    top:50%;
    left: 0;
    z-index: 5;
}

.share-to{
    display: flex;
    z-index: 5;

    flex-direction: column;
    gap:15px;
    width: 10%;
    padding: 12px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;

    border: 2px solid white;
    border-left: 0;
}
.share-to img{
    width: 100%;
    height: auto;
}

.blogabout-heading{
    margin: 4% 0;
    background-color: #0e0d27;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100vh;
}

.blogabout-heading::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #0e0d27;
    opacity: 0.68;
    z-index: 2;
}

.blogabout-heading img{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

#firstc{
    margin-top:17px;
}

.blogabout-heading h1{
z-index: 5;
font-size: 50px;
color:white;
}


.blog-content-c{
    width: 85%;
    margin: 60px auto;
}

.blog-content{
    display: grid;
    gap:4%;
    text-align: justify;
    grid-template-columns: 70% 30%;
}

.blog-text {
    margin: 120px 0;
}

.blog-text h2{
font-size: 25px;
margin: 17px 0;
color:#ffffff;
}

.blog-text p{
    font-size: 19px;
    margin: 17px 0;
    color: rgb(189, 222, 255);}



.writer{
    border: 2px solid #bfcaef;
    padding: 12px;
    background-color: #0e0d27;
    border-radius: 12px;
    position: relative;
    color: white;
    z-index: 10;
    -webkit-box-shadow: 3px 3px 1px 0px #bfcaef;
-moz-box-shadow: 3px 3px 1px 0px #bfcaef;
box-shadow: 3px 3px 1px 0px #bfcaef;

}

/* .writer::after{
position: absolute;
content: '';
top:8px;
left: 8px;
width: 100%;
border-radius: 12px;
height: 100%;
border:2px solid #fff;
    color: #28257d !important;
z-index: 5;
    color: #28257d !important;

} */

.writer h2{
    color: #ffffff !important;
    font-size: 19px;
}

.writer p{
    color: #ffffff;
    font-size: 17px;
    margin: 12px 0;
    text-align: justify;
}

.writer img{
    width: 40px;
    height: auto;
}


.latest-post{
    display: flex;
    flex-direction: column;
    gap:10px;
    padding: 12px;
    border-radius: 12px;
    text-align: start;
    margin: 20px 0;
    z-index: 10;
    border: 2px solid #bfcaef;

    background-color: #0e0d27;
    color: #ffffff;
    position: relative;
    -webkit-box-shadow: 3px 3px 1px 0px #bfcaef;
    -moz-box-shadow: 3px 3px 1px 0px #bfcaef;
    box-shadow: 3px 3px 1px 0px #bfcaef;
}




.latest-post h2{
    color: #ffffff;
}

.ls-post{
    margin: 20px 0;
    cursor: pointer;
}

.ls-post h2{
    font-size: 20px;
}
.ls-post p{
    font-size: 16px;
    margin: 5px 0;

}


@media screen and (max-width:900px){

    .share-to-c{
        width: 5%;
        top:16%;
    }

    .blog-content{
        display: flex;
        flex-direction: column;
        gap:20px;
        align-items: center;
    }

    .writer{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
        gap:10px;
        margin: 12px auto;
    }

    .writer h2{
        display: none;
    }

    .writer img{
        width: 40px;
        height: auto;
    }

    .writer p{
        font-size: 16px;

    }

    .latest-post{
        width: 100%;
        margin: 40px auto;
        text-align: center;
    }

    .blog-wl .latest-post .ls-post{
        text-align: start ;
    }
}